@use '@reverbdotcom/cadence/styles/tools/mixins/body-min-height';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadow;
@use '@reverbdotcom/cadence/styles/tools/mixins/responsive-hover' as hover;

.trade-in-layout {
  @include body-min-height.body-min-height;
  width: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.trade-in-layout__details {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  background-color: var(--rc-color-background-card);
  padding: var(--rc-space-6);
  flex-direction: column;

  &.trade-in-layout__details--eligible {
    background-color: var(--rc-color-palette-tweed-orange-100)
  }

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-4);
  }
}

.trade-in-layout__form-section {
  @include body-min-height.body-min-height;
  display: flex;
  min-width: 50%;
  padding: var(--rc-space-6);
  justify-content: center;
  flex-shrink: 1;

  @include mobile {
    width: 100%;
    // stylelint-disable-next-line declaration-no-important
    min-height: fit-content !important;
    padding: var(--rc-space-6) var(--rc-space-4);
  }
}
