.trade-in-form {
  padding: var(--rc-space-12);

  &__form-section {
    display: flex;
    flex-direction: column;
    gap: var(--rc-space-2);
    padding-bottom: var(--rc-space-8);
  }

  &__label {
    font-size: var(--rc-font-size-700);
    font-weight: var(--rc-font-weight-bold);
  }

  &__title {
    font-size: var(--rc-font-size-600);
    margin: 0;
    letter-spacing: -0.0816rem;
    line-height: 2.75;
    padding-bottom: 0.5rem;
  }

  &__detail {
    color: var(--rc-color-text-secondary);
    font-size: var(--rc-font-size-400);
    letter-spacing: -0.0816rem;
    padding-bottom: 1rem;
  }
}

.trade-in-form--eligible__list {
  list-style: none;
  margin: 0;

  li {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 0.75rem;
    padding-bottom: 1rem;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    background-color: var(--rc-color-border-primary);
    border-radius: 50%;
    font-size: 1rem;
    font-weight: var(--rc-font-weight-bold);
    line-height: 1;
    flex-shrink: 0;
  }

  &__text {
    line-height: 1.5;
  }
}
