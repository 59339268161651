.seller-trade-in-details__inner {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--rc-space-4);

  @include mobile {
    margin-top: var(--rc-space-8);
  }
}

.seller-trade-in-details__title {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-700);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-title);
  letter-spacing: -0.0816rem;
  align-self: center;
  padding: 1rem;

  &.seller-trade-in-details__title--eligible {
    text-align: center;

    h1 {
      font-size: var(--rc-font-size-900);
      font-weight: var(--rc-font-weight-bold);
      color: var(--rc-color-palette-vu-orange-600);
      padding-bottom: 1rem;
      line-height: var(--rc-font-line-height-title);
    }

    h2 {
      font-size: var(--rc-font-size-900);
      font-weight: var(--rc-font-weight-bold);
      letter-spacing: -0.0625rem;
    }
  }

  &.seller-trade-in-details__title--ineligible {
    text-align: center;

    h1 {
      font-size: var(--rc-font-size-900);
      font-weight: var(--rc-font-weight-bold);
      padding-bottom: 1rem;
      line-height: var(--rc-font-line-height-title);
    }
  }
}

.seller-trade-in-details__image {
  img {
    width: 25rem;
    height: 25rem;

    @include mobile {
      width: 15rem;
      height: 15rem;
    }
  }
}

.seller-trade-in-details__item-details {
  display: flex;
  flex-direction: column;
  gap: 2;
  align-items: center;
}

.seller-trade-in-details__item-details__title {
  font-weight: var(--rc-font-weight-bold);
  font-size: var(--rc-font-size-350);
}

.seller-trade-in-details__item-details__make {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-400);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-utility);
  letter-spacing: 0.01rem;
}

.seller-trade-in-details__item-details__model {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-600);
  line-height: var(--rc-font-line-height-utility);
  font-weight: var(--rc-font-weight-medium);
  letter-spacing: -0.024rem;
  max-width: 25rem;
  text-align: center;
  margin-right: auto;
  margin-left: auto;

  @include mobile {
    font-size: var(--rc-font-size-400);
  }
}

.seller-trade-in-details__item-details__pricing {
  font-size: var(--rc-font-size-500);
  font-weight: var(--rc-font-weight-bold);
  letter-spacing: -0.024rem;
}
