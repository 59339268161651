@use '@reverbdotcom/cadence/styles/tools/mixins/body-min-height';

.sell-options-selector {
  @include site-wrapper;
  @include body-min-height.body-min-height;
  padding-top: var(--rc-space-8);
  background-color: var(--rc-color-background-module-muted);

  @include mobile {
    padding-bottom: var(--rc-space-12);
  }
}

.sell-options-selector__title {
  font-family: var(--rc-font-family-inter);
  font-size: var(--rc-font-size-600);
  font-weight: var(--rc-font-weight-bold);
  line-height: var(--rc-font-line-height-title);
  letter-spacing: -0.0816rem;
  align-self: center;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: var(--rc-space-12);
  margin-bottom: var(--rc-space-12);
}

.sell-options-selector__card-link {
  text-decoration: 'none';
  background: 'transparent';
  color: var(--rc-color-text-primary);

  &:hover {
    color: inherit;
  }
}

.sell-options-selector__cards {
  display: flex;
  justify-content: center;
  gap: var(--rc-space-10);

  @include mobile {
    flex-direction: column;
  }
}

.sell-options-selector__card {
  cursor: pointer;
  border: solid transparent 0;
  border-width: 0.1rem;
  border-color: var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-lg);
  height: 100%;
}

.sell_option_card__label-details {
  font-weight: var(--rc-font-weight-bold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--rc-font-size-600);
}

.sell_option_card__label-details__icon {
  width: var(--rc-font-size-700);
  height: var(--rc-font-size-700);
  background-color: var(--rc-color-button-main-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.563rem;
  }
}

.sell_option_card__label-details__tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-radius: var(--rc-border-radius-sm);
  margin-top: var(--rc-space-2);
  margin-bottom: var(--rc-space-2);
  width: fit-content;
  white-space: nowrap;
  padding: var(--rc-space-1);

  &.sell_option_card__label-details__tooltip__trade-in {
    background-color: var(--rc-color-background-alert-highlight);
    color: var(--rc-color-text-accent);
  }

  &.sell_option_card__label-details__tooltip__sell {
    background-color: var(--rc-color-background-alert-info);
    color: var(--rc-color-border-alert-info);
  }

  p {
    margin-bottom: 0;
    font-weight: var(--rc-font-weight-bold);
    font-size: var(--rc-font-size-300);
    white-space: nowrap;
  }
}

.sell_option_card__icon {
  display: flex;
  padding-bottom: var(--rc-space-1);
  font-size: 150%;
}

.sell_option_card__value-props {
  border: solid transparent 0;
  border-top-width: 0.1rem;
  border-color: var(--rc-color-border-primary);
  font-size: var(--rc-font-size-400);
  font-weight: var(--rc-font-weight-normal);
  padding: var(--rc-space-2);

  ul {
    font-size: 80%;
    padding: var(--rc-space-2);

    li {
      display: flex;
      margin-top: var(--rc-space-1);
    }
  }
}
